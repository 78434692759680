import Link from "next/link";

export default function LinkToExternalPage(props: {
  url: string;
  openNewTab?: boolean;
  children: React.ReactChild | React.ReactChild[];
}) {
  const openNewTab = props.openNewTab ?? true;
  return (
    <Link href={props.url} passHref>
      <a
        target={openNewTab ? "_blank" : ""}
        rel="noreferrer"
        style={{ textDecoration: "inherit", color: "inherit", width:"100%", height:"100%" }}
      >
        {props.children}
      </a>
    </Link>
  );
}
