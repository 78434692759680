import { Container, useTheme, Typography } from "@material-ui/core";
import Card from "./Card";
import React, { useContext } from "react";
import { TextContext } from "@pages/index";

export default function Index() {
  const theme = useTheme();
  const text = useContext(TextContext);

  return (
    <Container style={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(6) }}>
      <Typography variant="h3" align="center" style={{ margin: theme.spacing(7) }}>
        {text.homepage_title}
      </Typography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fit, minmax(250px,1fr))`,
          columnGap: "20px",
          rowGap: "20px",
        }}
      >
        <Card
          icon="callSplitIcon"
          title={text.homepage_bijberoepStatuutToolTileTitle}
          link={text.navigation_bepaalStatuutToolLink}
          description={text.homepage_bijberoepStatuutToolTileDescription}
          buttonText={text.homepage_toTheToolButtonText}
        />
        <Card
          icon="euroSymbolIcon"
          title={text.homepage_berekenBijdrageToolTileTitle}
          link={text.navigation_berekenBijdrageToolLink}
          description={text.homepage_berekenBijdrageToolTileDescription}
          buttonText={text.homepage_toTheToolButtonText}
        />
        <Card
          icon="equilizerIcon"
          title={text.homepage_nettoPlannerToolTileTitle}
          link={text.navigation_nettoPlannerToolLink}
          description={text.homepage_nettoPlannerToolTileDescription}
          buttonText={text.homepage_toTheToolButtonText}
        />
        <Card
          icon="eventAvailableIcon"
          title={text.homepage_stappenplanToolTileTitle}
          link={text.navigation_stappenPlanToolLink}
          description={text.homepage_stappenplanToolTileDescription}
          buttonText={text.homepage_toTheToolButtonText}
        />
      </div>
    </Container>
  );
}
