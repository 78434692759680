import LinkToExternalPage from "@components/navigation/helpers/LinkToExternalPage";
import { Button, Card, Container, makeStyles, Typography, useTheme } from "@material-ui/core";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import theme from "@styles/theme";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "330px",
    maxWidth: "350px",
    boxShadow: theme.shadows[5],
    "&:hover": {
      boxShadow: theme.shadows[10],
    },
  },
});

const iconOptions = {
  callSplitIcon: <CallSplitIcon color="secondary" fontSize="large" />,
  euroSymbolIcon: <EuroSymbolIcon color="secondary" fontSize="large" />,
  equilizerIcon: <EqualizerIcon color="secondary" fontSize="large" />,
  eventAvailableIcon: <EventAvailableIcon color="secondary" fontSize="large" />,
};

export default function CardAcerta(props: {
  icon: "callSplitIcon" | "euroSymbolIcon" | "equilizerIcon" | "eventAvailableIcon";
  title: string;
  description: string;
  buttonText: string;
  link: string;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const icon = iconOptions[props.icon];

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
      <Card className={classes.root}>
        <Container
          style={{
            height: "100%",
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
          }}
        >
          <div
            style={{
              height: "70px",
              marginBottom: theme.spacing(0),
            }}
          >
            <Typography variant="h6" align="center">
              {props.title}
            </Typography>
          </div>
          <div style={{ textAlign: "center", paddingBottom: theme.spacing(2) }}>{icon}</div>
          <Typography
            variant="body1"
            align="left"
            style={{ fontSize: "15px", height: "80px", color: theme.palette.grey[600] }}
          >
            {props.description}
          </Typography>
          <div style={{ paddingTop: "16px", paddingBottom: "12px" }}>{/* <Divider light /> */}</div>
          <LinkToExternalPage url={props.link} openNewTab={false}>
            <Button variant="outlined" color="secondary" fullWidth>
              {props.buttonText}
            </Button>
          </LinkToExternalPage>
        </Container>
      </Card>
    </div>
  );
}
