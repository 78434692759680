import Index from "@components/index/Index";

import axios from "axios";
import React from "react";
import fallbackText from "../public/cms/tools.acerta.be.json";
import { PlatformText } from "@cms/types";
import selectText from "@helpers/selectText";
import htmlToMarkdown from "@helpers/htmlToMarkdown";
import { useRouter } from "next/router";

// For better typing: create a type from the fallBackText
type TextKeys = keyof typeof fallbackText;
export type Text = {
  [P in TextKeys]: string;
};

export const TextContext = React.createContext<undefined | PlatformText>(undefined);

export default function bepaalStatuut(props: { cmsData: any }) {
  const router = useRouter();
  const language = router.locale;
  const text = selectText(props.cmsData, language as "nl"|"fr"|"en") as PlatformText;
  return (
    <TextContext.Provider value={text}>
      <Index />
    </TextContext.Provider>
  );
}

export async function getStaticProps() {
  try {
    // replace this with the Dropsolid endpoint
    const cmsUrl = "http://labelcms.acerta.be/node/8?_format=json";
    const req = await axios.get(cmsUrl);
    console.log("successfully fetched content from drupal")
    const data = req.data.field_acerta_translations_field;
    const markdownData = htmlToMarkdown(data);
    return { props: { cmsData: markdownData } };
  } catch (e) {
    console.error("could not fetch text from the cms, using the fallback option");
    // console.error(e)
    return { props: { cmsData: fallbackText } };
  }
}

